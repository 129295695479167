"use client";

import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cn } from "../../utils";
import { cva } from "class-variance-authority";

const Tabs = TabsPrimitive.Root;

interface TabsListProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> {
  size?: "sm" | "md";
}
const TabsList = React.forwardRef<React.ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
  ({ className, size, ...props }, ref) => (
    <TabsPrimitive.List ref={ref} className={tabsListStyle({ className, size })} {...props} />
  ),
);
TabsList.displayName = TabsPrimitive.List.displayName;

// Extend the component props type with your additional 'size' prop
type TabsTriggerProps = React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
  size?: "sm" | "md";
};

const TabsTrigger = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, TabsTriggerProps>(
  ({ className, size, ...props }, ref) => (
    <TabsPrimitive.Trigger ref={ref} className={tabsTriggerStyle({ className, size })} {...props} />
  ),
);
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "ring-offset-background focus-visible:ring-ring radix-state-active:mt-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };

// Define button styles with 'cva', focusing on size variants
const triggerVariants = cva("", {
  variants: {
    // Define size variants
    size: {
      sm: "px-3 ",
      md: "h-9 py-2 px-1", // For 'md', add 'h-9' to increase height
    },
  },
  // Optionally, you can set a default size if you often use one size more than the other
  defaultVariants: {
    size: "sm",
  },
});

const listVariants = cva("", {
  variants: {
    size: {
      sm: "h-9 p-1",
      md: "h-12 p-2",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

/** Declared separately so we can use it for framework components like NavLink */
export const tabsListStyle = ({ className, size }: { className?: string; size?: "sm" | "md" }) => {
  return cn(
    "bg-muted text-muted-foreground inline-flex items-center justify-start gap-x-2 rounded-lg",
    listVariants({ size }),
    className,
  );
};

export const tabsTriggerStyle = ({ className, size }: { className?: string; size?: "sm" | "md" }) => {
  return cn(
    "ring-offset-background focus-visible:ring-ring data-[state=active]:bg-background data-[state=active]:text-foreground inline-flex items-center justify-center whitespace-nowrap rounded-md py-1 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:shadow",
    triggerVariants({ size }),
    className,
  );
};
